<template>
	<van-popup v-model:show="showwcdownload" position="bottom" :closeable="true" :style="{ height: '100%' }">
		<div style="height:95%;overflow:auto;">
			<van-image :src="mycardimage"></van-image>
		</div>
		<div style="text-align:center;line-height:32px;background:#FFF;height:5%;">请长按保存图片</div>
	</van-popup>
	<div class="vcarddetail" ref="imageWrapperout">
		<section class="cardframe">
			<div class="cardinfo" :class="this.cardstyle.isrounded?'cardrounded':''"
				:style="{backgroundColor: this.cardstyle.cardbgcolor, backgroundImage: (this.cardstyle.cardbgimage?('url(' + this.cardstyle.cardbgimage + ')'):'')}">
				<div class="avatorwraper">
					<van-image round :src="this.avatar" class="avatorimg" />
				</div>
				<div class="cardtitle">
					<h1>{{this.cdata.LastName}}{{this.cdata.FirstName}}</h1>
					<h2>{{this.cdata[this.cardstyle.subheadfield]}}</h2>
				</div>
				<div class="contactinfowraper">
					<ul class="contactinfo">
						<template v-for="(key) in this.cardstyle.cardfields" v-bind:key="key">
							<li v-if="(key=='Mobile' || key=='Phone') && this.cdata[key]"
								v-show="this.cdata[key]?true:false"><label>{{this.LANG[key]}}</label><a
									:href="'tel:' +this.cdata[key]">{{this.cdata[key]}}</a></li>
							<li v-else-if="key=='Email' && this.cdata[key]" v-show="this.cdata[key]?true:false">
								<label>{{this.LANG[key]}}</label><a
									:href="'mailto:' +this.cdata[key]">{{this.cdata[key]}}</a>
							</li>
							<li v-else-if="this.cdata[key]" v-show="this.cdata[key]?true:false">
								<label>{{this.LANG[key]}}</label>{{this.cdata[key]}}
							</li>
						</template>
					</ul>
				</div>
			</div>
		</section>
		<section class="qrcode">
			<van-cell-group inset>
				<div class="qrcodein">
					<div class="qrcodewraper">
						<van-image :src="this.qrcodeimageurl" width="120" height="120" style="border:1px solid #DDD;">
							<template v-slot:loading>
								<van-loading type="spinner" size="20" />
							</template>
						</van-image>
						<br />
						<label>通讯录二维码</label>
					</div>
					<div class="qrcodewraper">
						<van-image :src="this.VCardPageUrlQRCode" width="120" height="120"
							style="border:1px solid #DDD;">
							<template v-slot:loading>
								<van-loading type="spinner" size="20" />
							</template>
						</van-image>
						<br />
						<label>在线名片码</label>
					</div>
				</div>
			</van-cell-group>
		</section>
		<section class="profile">
			<van-cell-group inset>
				<ul>
					<li v-for="(item) in this.resinfolist" v-bind:key="item"
						v-show="item.fields && item.fields.length > 0 && this.cdata[item.fields[0]]">
						<label class="icon">
							<van-icon :name="item.icon"></van-icon>
						</label><span>
							<template v-for="subitem in item.fields" v-bind:key="subitem">
								<span v-if="subitem=='Mobile' || subitem=='Phone'"><a
										:href="'tel:' + this.cdata[subitem]">{{this.cdata[subitem]}}</a></span>
								<span v-else-if="subitem=='Website'"><a
										:href="this.cdata[subitem]">{{this.cdata[subitem]}}</a></span>
								<span v-else-if="subitem=='Email'"><a
										:href="'mailto:' + this.cdata[subitem]">{{this.cdata[subitem]}}</a></span>
								<span v-else>{{this.cdata[subitem]}}</span>
								<i>{{this.LANG[subitem]}}</i>
							</template>
						</span>
					</li>
				</ul>
			</van-cell-group>
		</section>
	</div>
	<section class="download" style="padding-bottom:80px;">
		<van-cell-group inset>
			<div class="downloadin">
				<van-button @click="onGeneratePic();" type="success" icon="down">下载</van-button>
			</div>
		</van-cell-group>
	</section>
	<van-tabbar v-if="this.loginvcuid" v-model="seltabbar">
		<van-tabbar-item icon="home-o" :to="'/vcard/' + this.loginvcuid">名片</van-tabbar-item>
		<van-tabbar-item icon="edit" to="/editvcard">编辑</van-tabbar-item>
		<van-tabbar-item icon="friends-o" to="/sharevcard">分享</van-tabbar-item>
		<van-tabbar-item icon="setting-o" @click="this.onExit();">退出</van-tabbar-item>
	</van-tabbar>
</template>
<style>
	html,
	body {
		background-color: #f7f8fa;
		color: #323233;
	}

	a,
	a:hover,
	a:focus {
		color: #323233;
	}

	.cardframe {
		padding: 16px;
	}

	.cardinfo {
		display: block;
		position: relative;
		background-size: cover;
		background-position: center;
		height: 208px;
	}

	.cardtitle {
		padding-left: 26px;
		padding-top: 34px;
		letter-spacing: 2px;
		text-align: left;
		color: #fffef8;
	}

	.cardtitle h1,
	.cardtitle h2 {
		margin: 0px;
		padding: 0px;
		line-height: 1.5em;
		font-weight: normal;
	}

	.cardtitle h1 {
		font-size: 22px;
	}

	.cardtitle h2 {
		font-size: 14px;
	}

	.contactinfowraper {
		padding-left: 26px;
		height: 110px;
		display: table-cell;
		width: 300px;
		vertical-align: middle;
	}

	.contactinfo {
		list-style: none;
		color: #fffef8;
		line-height: 1.8em;
		font-size: 12px;
		margin: 0px;
		padding: 0px;
		display: inline-block;
	}

	.contactinfo li {
		margin: 0px;
		padding: 0px;
	}

	.contactinfo li label:after {
		content: '：';
	}

	.contactinfo li a {
		color: #fffef8;
	}

	.cardrounded {
		border-radius: 8px;
	}

	.avatorwraper {
		display: block;
		right: 24px;
		top: 32px;
		position: absolute;
	}

	.avatorimg {
		width: 60px;
		height: 60px;
	}

	.vcarddetail {
		padding-bottom: 20px;
		font-family: Helvetica, Tahoma, Arial, 'PingFang SC', 'Hiragino Sans GB', 'Heiti SC', STXihei, 'Microsoft YaHei', SimHei;
	}

	.profile ul {
		margin: 0px;
		padding: 8px;
	}

	.profile ul li {
		margin: 0px;
		padding: 8px;
		list-style: none;
		border-bottom: 1px solid #EEE;
	}

	.profile ul li:last-child {
		border: none;
	}

	.profile ul li label,
	.profile ul li span {
		display: table-cell;
		vertical-align: middle;
		vertical-align: top;
		color: #666;
	}

	.profile ul li span {
		font-size: 13px;
	}

	.profile ul li label {
		font-size: 28px;
		padding-right: 18px;
		text-align: center;
	}

	.profile ul li span i {
		display: inline-block;
		padding-bottom: 5px;
		line-height: 1em;
		font-size: 11px;
		color: #AAA;
		font-style: normal;
	}

	.boardtitle {
		margin: 12px;
		padding: 0px;
		line-height: 32px;
		font-size: 13px;
		font-weight: normal;
		padding-left: 12px;
		color: #999;
		background-color: #F7F7F7;
		border-radius: 16px;
	}

	.qrcode {
		text-align: center;
		padding-bottom: 16px;
		font-size: 13px;
		line-height: 24px;
		color: #888;
	}

	.qrcodein {
		padding-top: 16px;
	}

	.qrcodewraper {
		display: inline-block;
		vertical-align: top;
		width: 120px;
		height: 160px;
		padding: 10px;
		text-align: center;
	}

	.downloadin {
		padding: 8px;
		text-align: center;
	}
</style>
<script>
	import {
		setCookie,
		getCookie,
		getIsWxClient
	} from '../util/index.js'

	import {
		Dialog
	} from 'vant'
	import html2canvas from "html2canvas"

	export default {
		name: 'ShareVCard',
		emits: ['onLoadingData'],
		components: {
			//BottomNav,
		},
		data() {
			return {
				clientWidth: document.documentElement.clientWidth,
				clientHeight: document.documentElement.clientHeight,
				showqrcodeboard: false,
				qrcodeimageurl: '',
				ownthiscard: false,
				tagpageindex: 0,
				resinfolist: [],
				loginvcuid: '',
				cardstyle: {
					isrounded: true,
					cardbgcolor: '#1f4e82',
					cardbgimage: 'static/images/vcardbg1.png',
					subheadfield: 'Company',
					cardfieldstitle: true, //是否显示标题
					cardfields: ['Mobile', 'Email', 'FullAddress'],
					detailfields: [{
						'icon': 'phone-o',
						'fields': ['Mobile', 'Phone', 'Fax']
					}, {
						'icon': 'free-postage',
						'fields': ['Email']
					}, {
						'icon': 'cluster-o',
						'fields': ['Company']
					}, {
						'icon': 'location-o',
						'fields': ['FullAddress']
					}, {
						'icon': 'aim',
						'fields': ['ZipCode']
					}, {
						'icon': 'guide-o',
						'fields': ['Website']
					}, {
						'icon': 'comment-o',
						'fields': ['Summary']
					}],
					contactfields: ['Mobile', 'Phone', 'Fax', 'Email', 'FullAddress', 'ZipCode', 'Website']
				},
				vcuid: '',
				cdata: 0,
				avatar: 'static/images/avatar.png',
				LANG: 0,
				isWeChat: false,
				CVFileUrl: '',
				showeditcardboard: false,
				showstyleboard: false,
				activedtab: 0,
				seltabbar: 2,
				showwcdownload: false,
				mycardimage:'',
			}
		},
		beforeRouteUpdate(to, from, next) {
			if (to.fullPath != from.fullPath) {
				next();
				console.log(to.params.vcuid);
				this.vcuid = to.params.vcuid;
				this.refreshPageInfo();
			}
		},
		created() {
			window.addEventListener('resize', this.handleResize);
		},
		methods: {
			onStyleSettings() {
				this.showstyleboard = true;
			},
			onGeneratePic() {
				html2canvas(this.$refs.imageWrapperout, {
					dpi: 300
				}).then(canvas => {
					//let dataURL = canvas.toDataURL("image/png");
					//this.capturedurl = dataURL;
					if (getIsWxClient()) {
						this.mycardimage = canvas.toDataURL();
						this.showwcdownload = true;
					} else {
						let a = document.createElement('a');
						a.href = canvas.toDataURL();
						a.download = '我的电子名片';
						a.click();
					}
					//console.log(getIsWxClient());
				});
			},
			handleResize() {
				this.clientWidth = document.documentElement.clientWidth;
				this.clientHeight = document.documentElement.clientHeight;
			},
			onAddBCard() {
				console.log('clicked');
				if (this.isWeChat)
					this.showqrcodeboard = true;
				else
					document.location = this.CVFileUrl;
			},
			onEditCard() {
				//this.cardstyle.subheadfield = 'Job';
				//this.cardstyle.cardfields = ['Mobile', 'Fax', 'Email', 'FullAddress'];
				this.showeditcardboard = true;
			},
			getIsWxClient() {
				var ua = navigator.userAgent.toLowerCase();
				if (ua.match(/MicroMessenger/i) == "micromessenger") {
					return true;
				}
				return false;
			},
			onCardStyleUpdated() {
				if (!this.timercs) {
					this.timercs = true;
					let that = this;
					setTimeout(function() {
						that.timercs = false;
						let postobj = new Object();

						postobj.cardstyle = that.cardstyle;
						postobj.vcuseruid = getCookie("vcuseruid");
						postobj.vcsessiontoken = getCookie("vcsessiontoken");
						console.log(postobj);
						that.axios.post('api/?entry=user&op=savevcstyle', postobj).then((response) => {
							console.log(response);
						});
					}, 500)
				}
			},
			onExit() {
				Dialog.confirm({
						title: '退出确认',
						message: '即将退出登录，您确定吗？',
					})
					.then(() => {
						setCookie("vcuseruid", '');
						setCookie("vcsessiontoken", '');
						this.$router.push('/login');
					})
					.catch(() => {
						// on cancel
					});
				this.seltabbar = 0;
			},
			refreshPageInfo() {
				this.ownthiscard = ((this.loginvcuid && this.loginvcuid == this.vcuid) ? true : false);
				this.$emit("onLoadingData", true);
				this.axios.get('api/?entry=bcard&op=getvcinfo&vcardid=' + this.vcuid).then((response) => {
					console.log(response);
					this.cdata = response.data.vcardinfo;
					if (response.data.errno != 0) {
						this.$emit("onLoadingData", false);
						this.$router.push("/login");
						return;
					}

					document.title = this.cdata['LastName'] + this.cdata['FirstName'];

					if (this.cdata['HeadImage'])
						this.avatar = 'api/' + this.cdata['HeadImage'];

					if (this.cdata['Settings'])
						this.cardstyle = JSON.parse(this.cdata['Settings']);

					this.resinfolist = [];

					for (var key in this.cardstyle.detailfields) {
						var infitem = this.cardstyle.detailfields[key];
						var showitem = Array();
						showitem.icon = infitem.icon;
						showitem.fields = Array();

						for (var subkey in infitem.fields) {
							var infsubitem = infitem.fields[subkey];

							if (!this.cdata[infsubitem])
								continue;

							showitem.fields[subkey] = infsubitem;
						}

						if (showitem.fields.length <= 0)
							continue;
						this.resinfolist.push(showitem);
					}
					//console.log(this.resinfolist);
					this.$emit("onLoadingData", false);
				});
			}
		},
		watch: {
			'cardstyle.cardbgcolor'(val) {
				//console.log(val);
				this.cardstyle.cardbgcolor = val;
				this.onCardStyleUpdated();
			},
			'cardstyle.cardfields'() {
				//console.log(val);
				this.onCardStyleUpdated();
			},
			'cardstyle.subheadfield'() {
				//console.log(val);
				this.onCardStyleUpdated();
			},
			clientWidth(val) {
				// 为了避免频繁触发resize函数导致页面卡顿，使用定时器
				if (!this.timerw) {
					// 一旦监听到的clientWidth值改变，就将其重新赋给data里的clientWidth
					this.clientWidth = val
					this.timerw = true
					let that = this
					setTimeout(function() {
						that.timerw = false;
					}, 100)
				}
			},
			clientHeight(val) {
				if (!this.timerh) {
					// 一旦监听到的clientHeight值改变，就将其重新赋给data里的clientHeight
					this.clientHeight = val
					this.timerh = true
					let that = this
					setTimeout(function() {
						that.timerh = false;
					}, 100)
				}
			}
		},
		mounted() {
			this.loginvcuid = getCookie('vcuseruid');
			if (this.loginvcuid == 'undefined')
				this.loginvcuid = '';

			this.vcuid = this.loginvcuid;
			console.log(this.vcuid);
			this.ownthiscard = ((this.loginvcuid && this.loginvcuid == this.vcuid) ? true : false);
			this.LANG = this.LOCAL['VCardDetail'];
			this.isWeChat = this.getIsWxClient();
			this.CVFileUrl = 'api/?entry=bcard&op=getvcard&vcardid=' + this.vcuid + '&span=' + Date.now();
			this.qrcodeimageurl = 'api/?entry=bcard&op=getvcode&vcardid=' + this.vcuid;
			this.VCardPageUrl = 'http://vcard.wbaohe.com/#/vcard/' + this.vcuid;
			this.VCardPageUrlQRCode = 'api/?entry=bcard&op=getqrcode&content=' + encodeURIComponent(this.VCardPageUrl);


			this.qrcodeimageurl = 'api/?entry=bcard&op=getvcode&vcardid=' + this.vcuid;

			this.refreshPageInfo();
		}
	}
</script>
